<template>
  <div>
    <div class="list-group settings">
      <div class="gitlab-user-info list-group-item">
        <Header/>
        <Main/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import Main from './Main.vue';

export default {
  name: 'SettingsMain',
  data() {
    return {
      social: {
        gitlab_username: null,
        twitter_handle: null,
        linkedIn_url: null,
      },
      widget: {
        showExplore: true,
        showWolfram: true,
        showWikidata: true,
        show3DViewer: true,
      },
    };
  },
  methods: {
  },
  components: {
    Header,
    Main,
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.gitlab-user-info {
}
</style>
