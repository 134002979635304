<template>
    <div>
        <h3>{{ $t("page_accessibility--title") }}</h3>
    </div>
</template>
<script>
export default {
  name: 'header',
  methods: {

  },
};
</script>
<style lang="">

</style>
