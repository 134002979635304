<template>
  <div class="accessibility">
    <div class="accessibility__input-group input-group mb-3">
      <span class="input-group-text" id="inputGroup-sizing-default">{{
        $t("font-size--title")
      }}</span>
      <input
        type="text"
        v-model="size"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-default"
        readonly
      />
      <input
        type="range"
        v-model="size"
        :min="min"
        :max="max"
        class="form-range ed-slider"
        id="customRange2"
      />
      <div class="form-check">
        <input v-model="toggleEddieSearch" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          Connect <strong>search</strong> with <strong>Eddie</strong>
          </label>
          </div>
       </div>
    <div class="accessibility__input-group input-group mb-3">
      <button class="ed-btn" @click="this.saveSize">
        {{ $t("global_save") }}
      </button>
    </div>
  </div>
</template>
<script>
// import mapActions from 'vuex';
import Configs from './config.json';

export default {
  name: 'Main',
  data() {
    return {
      size: Configs['font-size--default'],
      min: Configs['font-size--minimum'],
      max: Configs['font-size--maximum'],
      toggleEddieSearch: false,
    };
  },
  mounted() {
    this.getFromLocalStorage();
    this.saveSize();
    this.toggleEddieSearch = localStorage.getItem('is_eddie_search') === 'true';
  },
  watch: {
    size(val) {
      this.size = val;
    },
    toggleEddieSearch(val) {
      localStorage.setItem('is_eddie_search', val);
    },
  },
  methods: {
    saveToLocalStorage() {
      localStorage.setItem('font-size', this.size);
    },
    getFromLocalStorage() {
      const fontSize = localStorage.getItem('font-size') || Configs['font-size--default'];

      this.size = fontSize;
    },
    saveSize() {
      this.$store.dispatch('setGlobalFontSize', {
        fetchFromLocal: false,
        _fontSize: this.size,
      });
    },
  },
};
</script>
<style lang="scss">
.accessibility {
  &__input-group {
    max-width: 40rem;
  }
}
</style>
